// dfields

import http from '../../../store/api/http' 

const actions = {
    getFields(context) {
		return new Promise((resolve, reject) => {
			http.get('/api/dfields')
			.then(function (response) {
		    	context.commit('getDFields', response.data)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    updateField(context, data) {
		return new Promise((resolve, reject) => {
			http.patch('/api/dfields/' + data.id, data)
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    getTables(context) {
		return new Promise((resolve, reject) => {
			http.get('/api/dtables')
			.then(function (response) {
		    	context.commit('getDTables', response.data)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    updateTables(context, data) {
		return new Promise((resolve, reject) => {
			http.patch('/api/dtables', data)
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },

};

export default actions;